import { AppwriteException } from "appwrite";
import { AxiosErrorObject } from "../../../../utils/interfaces/user";

const extractErrorMessage = (error: AxiosErrorObject | null): string | null => {
  if (error?.response?.data && error?.response?.data?.message) {
    if (error?.response?.data?.message?.includes("Too Many Attempts")) {
      return "Please wait for 60 seconds before attempting again following a hard reload due to excessive api calls";
    }
    return error?.response?.data?.message;
  }
  return "Something went wrong, please try again.";
};

export default extractErrorMessage;

export const formateErrorAppwriteObject = (error: AppwriteException) => {
  if (error?.type === "user_already_exists") {
    return "Your account already exists, Please login";
  }

  return error?.message;
};
