import { Dictionary } from "lodash";
import { useState } from "react";
import apiClient from "../../services/base";
import { Endpoints } from "../../network";
import { useNavigate } from "react-router-dom";
import {
  formatErrorObject,
  removeAdminRole,
  removeAuth,
  setToken,
} from "../../utils/lib/auth";
// import { useRecoilState } from "recoil";
// import { UserData } from "../../utils/interfaces/user";
// import { user } from "../../atoms/toggleTheme/user";
import { notify } from "../../components/shared/basic/notify";
import { user } from "../../atoms/toggleTheme/user";
import { AppwriteUserData, UserData } from "../../utils/interfaces/user";
import { useRecoilState } from "recoil";
import appwriteService, { setTokenInCookies } from "../../config/appwrite";
import { appWriteUser } from "./useLogin";
import { tokenUpdate } from "../../atoms/authModalControl";
import { formateErrorAppwriteObject } from "../../components/shared/basic/formateError";

interface SignUpOptions {
  email: string;
  password: string;
  name: string;
  phone: string;
  isModal?: boolean;
  onCancel?: () => void;
  noRedirect?: boolean;
  isRegister?: boolean;
  registerClose?: () => void;
}

export const useSignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [, setUserData] = useRecoilState<UserData | undefined>(user);
  const [, setIsTokenChanged] = useRecoilState(tokenUpdate);

  const router = useNavigate();

  const onSignUp = async (
    options: SignUpOptions
  ): Promise<Dictionary<string | JSX.Element>> => {
    const { email, password, name } = options;
    setLoading(true);

    let notificationSent = false;

    const handleAssignAffiliate = async () => {
      try {
        await apiClient.get(Endpoints.affiliateCheck);
      } catch (error: any) {
        const message = formateErrorAppwriteObject(error);
        notify(message, "error");
      }
    };

    try {
      await appwriteService.logoutIfSessionExists();
      await appwriteService.createUserAccount({
        email,
        password,
        name,
      });
      await appwriteService.logoutIfSessionExists();
      const userLogin = await appwriteService.login({
        email,
        password,
      });
      const token = await setTokenInCookies();
      let currentUser: AppwriteUserData | undefined = undefined;
      await handleAssignAffiliate();
      if (userLogin) {
        const user = await appwriteService.getCurrentUser();
        if (user !== null) {
          currentUser = user as unknown as AppwriteUserData;
        }
      }
      const dbUser: UserData = await appWriteUser(currentUser?.$id as string);
      await handlePostSignUpData({ dbUser, token });
      router("/dashboard");
      notify("Successfully Signed Up!", "success");
      notificationSent = true;

      // Return an empty object in the success case
      return {};
    } catch (error: any) {
      const message = formateErrorAppwriteObject(error);

      if (!notificationSent) {
        notify(message, "error");

        notificationSent = true;
      }
    } finally {
      setLoading(false);
    }

    // If an error occurs or other code paths are taken, return an empty object
    return {};
  };

  const checkEmailExist = async (email: string) => {
    const response = await apiClient.post(`${Endpoints.emailExist}`, { email });
    return response?.data;
  };
  const handlePostSignUpData = async (response: {
    dbUser: UserData;
    token: string;
  }) => {
    await setToken(response?.token);

    setUserData(response?.dbUser);

    setIsTokenChanged(true);
  };

  const onLogOut = async (): Promise<Dictionary<string | JSX.Element>> => {
    const errorMessages: Dictionary<JSX.Element | string> = {};

    // const notificationSent = false;
    setUserData(undefined);

    removeAuth();
    removeAdminRole();

    try {
      await appwriteService.logout();
      setIsTokenChanged(true);
      router("/");
      notify("Logged out successfully", "success");
    } catch (error: any) {
      const message = formatErrorObject(error);

      console.log(message);
    } finally {
      setLoading(false);
    }
    return errorMessages;
  };

  return { loading, onSignUp, checkEmailExist, handlePostSignUpData, onLogOut };
};
