import { Button, DatePicker, Drawer, Layout, Menu, SelectProps } from "antd";

import React, { ReactNode, useEffect, useState } from "react";

import { LogoWhite, WhiteLogoIcon } from "../../assets";
import { CopyOutlined } from "@ant-design/icons";
import { LogoutIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

import {
  useNavigate,
  Link,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { formatErrorObject, getCode, setCode } from "../../utils/lib/auth";

import DashboardIcon from "../../assets/Icons/DashboardIcon";
import PersonIcon from "../../assets/Icons/PersonIcon";
import { Header } from "antd/es/layout/layout";
import { getUser } from "../../services/user";
import { useRecoilState } from "recoil";
import { AppwriteUserData, UserData } from "../../utils/interfaces/user";
import { user } from "../../atoms/toggleTheme/user";
import { notify } from "../shared/basic/notify";
import dayjs, { Dayjs } from "dayjs";
import { dateFilter, dateFilterCsv } from "../../atoms/dateFilter";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ShouldRender } from "../shared/basic/ShouldRender";
import ProductsIcon from "../../assets/Icons/ProductsIcon";
import clsx from "clsx";

import { affiliateCode } from "../../atoms/affiliateCode";

import appwriteService, { setTokenInCookies } from "../../config/appwrite";
import { appWriteUser } from "../../hooks/useAuth/useLogin";
import { useSignUp } from "../../hooks/useAuth/useSignUp";
import { tokenUpdate } from "../../atoms/authModalControl";
import IFrameIcon from "../../assets/Icons/IFrame";
import { handleXlsxIFrameFile } from "../../services/iframe";
import calculateDateDifference from "../shared/basic/daysDifference";
import { Roles } from "../../utils/interfaces/roles";
import apiClient from "../../services/base";
import { Endpoints } from "../../network";
import { formateErrorAppwriteObject } from "../shared/basic/formateError";

const { Sider, Content } = Layout;

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const router = useNavigate();
  const location = useLocation();
  const [, setUserData] = useRecoilState<UserData | undefined>(user);
  const [, setDateFilter] = useRecoilState(dateFilter);
  const [dateFilterValueCsv, setDateFilterCsv] = useRecoilState(dateFilterCsv);
  const [, setAffiliateCode] = useRecoilState(affiliateCode);
  const [, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const code = getCode() || "";
  const [isAdmin, setIsAdmin] = useState(false);
  const [iframeFileLoading, setIframeFileLoading] = useState(false);

  const { handlePostSignUpData, onLogOut } = useSignUp();
  const isDashboardRoute = (route: string) => {
    return route.startsWith("/dashboard") && route.match(/\/dashboard\/\d+/);
  };
  const zebraLearnBaseUrl = process.env.REACT_APP_ZEBRALEARN_BASE_URL;

  const isPaymentHistoryDetails = (route: string) => {
    return route.startsWith("/dashboard/paymentHistory/");
  };

  const [drawerVisible, setDrawerVisible] = useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const googleLoginCompleted = searchParams.get("googleLoginCompleted");
  const { months, remainingDays } = calculateDateDifference(
    dateFilterValueCsv?.start_date,
    dateFilterValueCsv?.end_date
  );
  const [scrolled, setScrolled] = useState(false);
  const screens = useBreakpoint();
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const routePath = location?.pathname ?? "";

  const getActiveMenuItem = (routePath: string) => {
    if (routePath.startsWith("/dashboard")) {
      return "1";
    } else if (routePath.startsWith("/profile-settings")) {
      return "2";
    } else if (routePath.startsWith("/products")) {
      return "3";
    } else if (routePath.startsWith("/iframe")) {
      return "4";
    }
    return "1";
  };

  const getActiveHeaderName = (routePath: string) => {
    if (routePath.startsWith("/dashboard")) {
      return "Dashboard";
    } else if (routePath.startsWith("/profile-settings")) {
      return "Profile Settings";
    } else if (routePath.startsWith("/products")) {
      return "Products";
    } else if (routePath.startsWith("/iframe")) {
      return "IFrame";
    }

    return "Dashboard";
  };

  const selectedKey = getActiveMenuItem(routePath);

  const activeHeaderName = getActiveHeaderName(routePath);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const getUserDetails = async () => {
    try {
      const data = await getUser();
      const isAdmin =
        data?.data?.data?.roles &&
        data?.data.data.roles.some((role: any) => {
          const adminRoles = [
            Roles.SUPER_ADMIN,
            Roles.MANAGER,
            Roles.AFFILIATE_MANAGER,
            Roles.ADMIN,
          ];
          return adminRoles.includes(role.name);
        });

      setIsAdmin(isAdmin);

      setAffiliateCode(data?.data?.data?.affiliate_user?.code);
      await setCode(data?.data?.data?.affiliate_user?.code);
      setUserData({
        phone: data?.data?.data?.phone,
        name: data?.data?.data?.name,
        email: data?.data?.data?.email,
        gender: data?.data?.data?.email,
        password: data?.data?.data?.password,
        role_id: data?.data?.data?.roles?.map((role: any) => {
          return {
            role: role?.id,
          };
        }),
        phoneNumber: data?.data?.data?.phone,
        role: data?.data?.data?.roles?.map((role: any) => {
          return {
            id: role?.id,
            name: role?.name,
          };
        }),
        is_new_user: data?.data?.data?.affiliate_user?.is_new_user,
      });
    } catch (error: any) {
      // notify("You are not authenticated", "error");
      console.log(error);
      // router(`/?redirectUrl=${location.pathname}`);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [router]);

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i?.toString(36) + i,
      label: i?.toString(36) + i,
    });
  }

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setDateFilter({
        start_date: dateStrings[0] as string,
        end_date: dateStrings[1] as string,
      });
    } else {
      setDateFilter({
        start_date: "" as string,
        end_date: "" as string,
      });
    }
  };
  const onRangeChangeCsv = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setDateFilterCsv({
        start_date: dateStrings[0] as string,
        end_date: dateStrings[1] as string,
      });
    } else {
      setDateFilterCsv({
        start_date: "" as string,
        end_date: "" as string,
      });
    }
  };

  const sidebarContent = (
    <div className="flex flex-col h-full justify-between">
      <div>
        <div className="demo-logo-vertical" />
        <span
          className={clsx(
            screens?.md
              ? "justify-center"
              : "justify-between items-center w-full",
            "flex px-7 pt-10   pb-5"
          )}
        >
          <img
            src={!screens?.md ? LogoWhite : WhiteLogoIcon}
            alt="Logo"
            className={clsx(
              screens?.md ? "h-6" : "h-4",
              "object-contain  w-fit cursor-pointer"
            )}
            onClick={() => router("/dashboard")}
          />
          <ShouldRender check={!screens.md}>
            <span
              onClick={() => setDrawerVisible(false)}
              className=" cursor-pointer group flex justify-center items-center rounded-full "
            >
              <XIcon className="w-6 h-6 group-hover:text-textGrayColor text-[#9A9A9A]" />
            </span>
          </ShouldRender>
        </span>
        <Menu
          style={{
            border: "none",
          }}
          className="mt-4 px-2.5 bg-bgColor border-none customMenuItems"
          mode="inline"
          selectedKeys={[selectedKey]}
        >
          <Menu.Item
            key="1"
            className="flex items-center justify-center"
            onClick={() => setDrawerVisible(false)}
            icon={
              <span className="md:pt-2 -translate-x-1">
                <DashboardIcon
                  outlined={selectedKey !== "1"}
                  fill={selectedKey !== "1" ? "#737373" : "white"}
                />
              </span>
            }
          >
            <Link to="/dashboard">Dashboard</Link>
            <div className="indicator" />
          </Menu.Item>
          <ShouldRender check={!isAdmin}>
            <Menu.Item
              key="2"
              className="flex items-center justify-center"
              onClick={() => setDrawerVisible(false)}
              icon={
                <span className="md:pt-2 translate-x-5 md:-translate-x-1">
                  <PersonIcon
                    outlined={selectedKey !== "2"}
                    fill={selectedKey !== "2" ? "#737373" : "white"}
                  />
                </span>
              }
            >
              <Link className="pl-6 md:pl-0" to="/profile-settings">
                Profile & Settings
              </Link>
              <div className="indicator" />
            </Menu.Item>
          </ShouldRender>

          <Menu.Item
            key="3"
            onClick={() => setDrawerVisible(false)}
            icon={
              <span className="md:pt-2 -translate-x-1">
                <ProductsIcon
                  outlined={selectedKey !== "3"}
                  fill={selectedKey !== "3" ? "#737373" : "white"}
                />
              </span>
            }
          >
            <Link to="/products">Products</Link>
            <div className="indicator" />
          </Menu.Item>
          <Outlet />
          <ShouldRender check={isAdmin}>
            <Menu.Item
              key="4"
              onClick={() => setDrawerVisible(false)}
              icon={
                <span className="md:pt-2  translate-x-5 md:-translate-x-1">
                  <IFrameIcon
                    outlined={selectedKey !== "4"}
                    fill={selectedKey !== "4" ? "#737373" : "white"}
                  />
                </span>
              }
            >
              <Link className="pl-6 md:pl-0" to="/iframe">
                IFrame
              </Link>
              <div className="indicator" />
            </Menu.Item>
          </ShouldRender>
        </Menu>
      </div>

      <div className="flex w-full justify-between px-5 pb-7 ">
        <Button
          icon={<LogoutIcon className="w-5" />}
          className=" w-full flex justify-center gap-x-3 h-10 items-center shadow-sm rounded-md "
          onClick={() => {
            onLogOut();
          }}
        >
          <ShouldRender check={!screens?.md}>Logout</ShouldRender>
        </Button>
      </div>
    </div>
  );

  const handleCopyLink = (url: string) => {
    navigator.clipboard
      .writeText(`${url}?afc=${code}`)
      .then(() => {
        notify("Link copied to clipboard successfully!!", "success");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard: ", error);
      });
  };

  const downloadXlsxFile = async () => {
    try {
      setIframeFileLoading(true);
      await handleXlsxIFrameFile({
        from: dateFilterValueCsv.start_date,
        to: dateFilterValueCsv.end_date,
      });
    } catch (error: any) {
      const message = formatErrorObject(error);
      notify(message, "error");
    } finally {
      setIframeFileLoading(false);
    }
  };

  const handleAssignAffiliate = async () => {
    try {
      await apiClient.get(Endpoints.affiliateCheck);
    } catch (error: any) {
      const message = formateErrorAppwriteObject(error);
      notify(message, "error");
    }
  };

  useEffect(() => {
    const fetchTokenAndTriggerChange = async () => {
      if (googleLoginCompleted === "true") {
        const token = await setTokenInCookies();
        await handleAssignAffiliate();
        await getUserDetails();
        const user = await appwriteService.getCurrentUser();
        const currentUser = user as unknown as AppwriteUserData;
        const dbUser: UserData = await appWriteUser(currentUser?.$id as string);
        await handlePostSignUpData({ dbUser, token });
        setIsTokenChanged(true);
        const { pathname } = location;
        router(pathname, { replace: true });
      }
    };
    fetchTokenAndTriggerChange();
  }, [googleLoginCompleted]);

  return (
    <Layout>
      {screens.md ? (
        <Sider
          trigger={null}
          collapsible
          collapsed={true}
          width={200}
          className="site-layout-background border-r-2 border-[#222222] z-40"
          style={{
            position: "fixed",
            height: "100vh",
            left: 0,
            zIndex: 1,
            backgroundColor: "#080808",
          }}
        >
          {sidebarContent}
        </Sider>
      ) : (
        <Drawer
          className="drawerPaddingNone overflow-hidden"
          width={450}
          placement="right"
          closable={false}
          onClose={() => setDrawerVisible(!drawerVisible)}
          open={drawerVisible}
          key="left"
        >
          {sidebarContent}
        </Drawer>
      )}

      <Layout
        style={{
          marginLeft: screens.md ? 80 : 0,
          transition: "margin-left 0.2s",
        }}
      >
        <ShouldRender
          check={
            !isDashboardRoute(location?.pathname) &&
            !isPaymentHistoryDetails(location?.pathname)
          }
        >
          <Header
            className={`z-40 flex justify-between items-end ${
              scrolled ? "scrolled" : ""
            }`}
            style={{
              padding: 0,
              background: "#080808",
              position: "sticky",
              width: "100%",
              height: scrolled ? "60px" : screens.md ? "90px" : "50px",
              alignItems: scrolled ? "center" : "end",
              top: 0,
              transition: "height 0.3s ease, align-items 1s ease",
            }}
          >
            <div
              className={clsx(
                activeHeaderName === "Dashboard" && "justify-between",
                !screens.md && "justify-between",
                "flex w-full px-4 md:pr-10 items-end gap-x-5 "
              )}
            >
              <span className=" flex justify-center items-center  gap-x-4  ">
                <ShouldRender check={!screens.md}>
                  <img
                    src={WhiteLogoIcon}
                    alt="Logo"
                    onClick={() => router("/dashboard")}
                    className="object-contain h-6 w-full"
                  />
                </ShouldRender>

                <p className="text-xl md:text-2xl font-medium text-fadedWhite md:pl-6 whitespace-nowrap">
                  {activeHeaderName}
                </p>
              </span>
              <ShouldRender
                check={screens.md && activeHeaderName === "Dashboard" && !id}
              >
                <span className="flex items-end">
                  <DatePicker.RangePicker
                    onChange={onRangeChange}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                    allowClear
                  />
                </span>
              </ShouldRender>
              <ShouldRender check={activeHeaderName === "IFrame" && isAdmin}>
                <div className="flex justify-end w-full mt-5 gap-x-4">
                  <Button
                    type="primary"
                    ghost
                    loading={iframeFileLoading}
                    disabled={iframeFileLoading}
                    onClick={() => {
                      downloadXlsxFile();
                    }}
                    size="middle"
                  >
                    <div className=" gap-x-2 inline-flex">
                      Download IFrame File (CSV){" "}
                      <ShouldRender check={months || remainingDays}>
                        <p className="">
                          (for last{" "}
                          <ShouldRender check={months}>
                            {months} months
                          </ShouldRender>{" "}
                          <ShouldRender check={remainingDays}>
                            {" "}
                            {remainingDays} days
                          </ShouldRender>
                          )
                        </p>
                      </ShouldRender>
                    </div>
                  </Button>
                  <span className="flex items-end">
                    <DatePicker.RangePicker
                      placeholder={["Start Date (CSV)", "End Data (CSV)"]}
                      onChange={onRangeChangeCsv}
                      disabledDate={(current) =>
                        current && current > dayjs().endOf("day")
                      }
                      allowClear
                    />
                  </span>
                </div>
              </ShouldRender>
              <ShouldRender check={activeHeaderName === "Products" && !isAdmin}>
                <Button
                  onClick={() => {
                    handleCopyLink(`${zebraLearnBaseUrl}` as string);
                  }}
                  className="text-primary border-[#501801] text-sm"
                  icon={<CopyOutlined className="w-4 h-4" />}
                >
                  Get Link{" "}
                  <ShouldRender check={screens?.md}>
                    {" "}
                    of Zebralearn
                  </ShouldRender>
                </Button>
              </ShouldRender>
              <ShouldRender check={!screens.md}>
                <span
                  onClick={() => showDrawer()}
                  className="flex items-center w-6 h-6 hover:cursor-pointer "
                >
                  {drawerVisible ? (
                    <XIcon className="w-full" />
                  ) : (
                    <MenuIcon className="w-full" />
                  )}
                </span>
              </ShouldRender>
            </div>
          </Header>
        </ShouldRender>
        <Content
          className="px-4 md:px-10 pb-10"
          style={{
            margin: "20px 0px 20px 0px",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
